<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h5 class="mb-4">
          <strong>Pasien</strong>
        </h5>
        <a-form :form="form" @submit="findPatient">
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Kode Pasien"
          >
            {{ $route.params.id }}
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="card" v-if="patientId">
      <div class="card-body">
        <h5 class="mb-4">
          <strong>Data Tes Laboratorium</strong>
        </h5>
        <a-form :form="form" @submit="findPatient">
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Jenis Tes"
            v-if="isAdmin"
          >
            {{
              testLab && testLab.testLabType ? testLab.testLabType.name : '-'
            }}
          </a-form-item>
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Dokter"
            v-if="isAdmin"
          >
            {{ testLab && testLab.doctor ? testLab.doctor.fullname : '-' }}
          </a-form-item>
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Laboratorium"
            v-if="isAdmin"
          >
            {{
              testLab && testLab.laboratorium ? testLab.laboratorium.name : '-'
            }}
          </a-form-item>
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Voucher"
            v-if="isAdmin"
          >
            {{ testLab && testLab.voucher ? testLab.voucher.code : '-' }}
          </a-form-item>
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Hasil Tes"
          >
            <a-upload
              :multiple="false"
              v-decorator="[
                'image',
                {
                  valuePropName: 'image',
                },
              ]"
              name="upload"
              :action="action"
              list-type="picture"
              :file-list="fileList"
              @change="handleChange"
              v-if="isAdmin"
            >
              <!-- <a-button> <a-icon type="upload" /> Click to upload </a-button> -->
            </a-upload>
            <a-upload
              :multiple="false"
              v-decorator="[
                'image',
                {
                  valuePropName: 'image',
                },
              ]"
              name="upload"
              :action="action"
              list-type="picture"
              :file-list="fileList"
              @change="handleChange"
              @preview="() => {}"
              v-else
            >
              <!-- <a-button> <a-icon type="upload" /> Click to upload </a-button> -->
            </a-upload>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="card" v-if="patientId">
      <div class="card-body">
        <h5 class="mb-4">
          <strong>Data Program</strong>
        </h5>
        <a-form :form="form" @submit="findPatient">
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Obat"
            v-if="isAdmin"
          >
            {{
              program && program.programType ? program.programType.name : '-'
            }}
          </a-form-item>
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Dokter"
            v-if="isAdmin"
          >
            {{ program && program.doctor ? program.doctor.fullname : '-' }}
          </a-form-item>
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Kode Verifikasi"
            v-if="isAdmin"
          >
            {{
              program && program.doctor ? program.doctor.verificationCode : '-'
            }}
          </a-form-item>
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Farmasi"
            v-if="isAdmin"
          >
            {{ program && program.pharmacy ? program.pharmacy.name : '-' }}
          </a-form-item>
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Resep Dokter"
          >
            <a-upload
              :multiple="false"
              v-decorator="[
                'image',
                {
                  valuePropName: 'image',
                },
              ]"
              name="upload"
              :action="action2"
              list-type="picture"
              :file-list="fileList2"
              @change="handleChange2"
              v-if="isAdmin"
            >
              <!-- <a-button> <a-icon type="upload" /> Click to upload </a-button> -->
            </a-upload>
            <a-upload
              :multiple="false"
              v-decorator="[
                'image',
                {
                  valuePropName: 'image',
                },
              ]"
              name="upload"
              :action="action2"
              list-type="picture"
              :file-list="fileList2"
              @change="handleChange2"
              @preview="() => {}"
              v-else
            >
              <!-- <a-button> <a-icon type="upload" /> Click to upload </a-button> -->
            </a-upload>
            <!-- <a-image
              v-if="fileList2.length"
              name="upload"
              :src="fileList2[0].url"
              fallback="https://placehold.jp/200x200.png?text=Not+Found"
              :width="200"
              :preview="{ visible: false }"
            >
            </a-image>
            <p>{{ JSON.stringify(fileList2[0].url) }}</p> -->
          </a-form-item>
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Status Program"
            v-if="isAdmin"
          >
            {{
              program
                ? program.checkPoint == 5
                  ? program.isDrugsTaken
                    ? 'Program Berjalan'
                    : 'Pengambilan Obat'
                  : program.isTerminated
                  ? 'Program Berhenti'
                  : 'Program Perpanjang'
                : '-'
            }}
          </a-form-item>
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Hasil Survey"
            v-if="isAdmin"
          >
            {{ surveyResult.id ? surveyResult.result.result : '-' }}
          </a-form-item>
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Redemption Date"
            v-if="isAdmin"
          >
            <span v-for="program in programs" :key="program.id">
              <a-tag color="blue">
                {{ moment(program.red).format('YYYY/MM/DD') }}
              </a-tag>
            </span>
            <!-- <a-tag color="orange">
              2021/05/14
            </a-tag> -->
            <a-popover
              title="Tambah Redemption Date"
              trigger="click"
              placement="bottom"
            >
              <template slot="content">
                <a-date-picker @change="onDateValueChange" /> <br />
                <button
                  @click="onClickAddRedemption(date)"
                  class="btn btn-info p-0 px-3 mt-2 btn-block"
                  style="border-radius: 0"
                  type="button"
                >
                  Add
                </button>
              </template>
              <button
                class="btn btn-info pt-0"
                style="border-radius: 15px; height: 24px"
                type="button"
              >
                +
              </button>
            </a-popover>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <button
      class="btn btn-success px-5"
      :disabled="submitting"
      @click="handleContinue"
      v-if="program && program.checkPoint === 5 && program.status && isAdmin"
    >
      Perpanjang
    </button>
    <button
      class="btn btn-danger px-5 m-1"
      :disabled="submitting"
      @click="
        (e) => {
          e.preventDefault()
          setModalVisible(true)
        }
      "
      v-if="program && program.checkPoint === 5 && program.status && isAdmin"
    >
      Berhenti
    </button>
    <button
      class="btn btn-secondary px-5"
      :disabled="submitting"
      @click="handleCancel"
    >
      Kembali
    </button>
    <a-modal
      title="Konfirmasi"
      :dialog-style="{ top: '20px' }"
      :visible="modalVisible"
      @ok="() => handleDelete()"
      @cancel="() => setModalVisible(false)"
    >
      <a-form>
        <a-form-item
          :label-col="labelCol2"
          :wrapper-col="wrapperCol2"
          label="Alasan Berhenti"
        >
          <a-select
            v-model="alasanBerhenti"
            :disabled="submitting"
            placeholder="Pilih Alasan Berhenti"
          >
            <a-select-option
              v-for="message in terminateMessageList"
              :key="message.value"
              :value="message.value"
            >
              {{ message.value }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      Apakah Anda yakin akan menghentikan program pasien {{ $route.params.id }}?
    </a-modal>
    <a-modal
      title="Konfirmasi"
      :dialog-style="{ top: '20px' }"
      :visible="modalVisible2"
      @ok="() => handleResetPassword(false)"
      @cancel="() => setModalVisible2(false)"
    >
      Apakah Anda yakin akan mereset password pasien
      {{ form.getFieldValue('fullname') }}? <br />
      email akan dikirimkan ke {{ form.getFieldValue('email') }}
    </a-modal>
  </div>
</template>
<script>
import router from '@/router'
import moment from 'moment'
import { getRedemptionByCode, getByCode } from '@/services/axios/api/pasien'
import {
  continueProgram,
  terminateProgramDashboard,
  createRedemptionProgram,
} from '@/services/axios/api/program'
import { resetPassword } from '@/services/axios/api/auth'
import { BASE_URL } from '@/config'
import { notification } from 'ant-design-vue'

const labelCol = {
  xs: { span: 24 },
  sm: { span: 4 },
}
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 12 },
}

const labelCol2 = {
  xs: { span: 24 },
  sm: { span: 5 },
}
const wrapperCol2 = {
  xs: { span: 24 },
  sm: { span: 12 },
}
export default {
  data: function () {
    return {
      form: this.$form.createForm(this),
      isAdmin: false,
      labelCol,
      wrapperCol,
      labelCol2,
      wrapperCol2,
      cityList: [],
      submitting: false,
      programs: [],
      testLabExist: false,
      programExist: false,
      patientId: '',
      testLab: null,
      alasanBerhenti: null,
      date: null,
      program: null,
      surveyResult: {},
      // action: `${BASE_URL}/upload/news`,
      action: `${BASE_URL}/upload/lab-result`,
      // action2: `${BASE_URL}/upload/news`,
      action2: `${BASE_URL}/upload/prescription`,
      fileList: [],
      fileList2: [],
      modalVisible: false,
      modalVisible2: false,
      terminateMessageList: [
        { value: 'Progresi penyakit' },
        { value: 'Meninggal dunia' },
        { value: 'Masalah biaya/finansial' },
        { value: 'Lain-lain' },
      ],
    }
  },
  created() {
    this.$store.state.patientData = []
    this.findPatient()
    this.getRole()
  },
  methods: {
    moment,
    onDateValueChange(val) {
      this.date = val
    },
    async onClickAddRedemption(date) {
      if (date !== null) {
        const res = await createRedemptionProgram({ id: this.program.id, date })
        if (res && res.data) {
          notification.success({
            message: 'Sukses',
            description: 'Redemption date berhasil ditambahkan',
            duration: 5,
          })
          this.$router.go()
        }
      } else {
        notification.warning({
          message: 'Gagal',
          description: 'Harap isi tanggal terlebih dahulu',
          duration: 5,
        })
      }
    },
    findPatient() {
      this.form.getFieldDecorator('code', {
        initialValue: this.$route.params.id,
      })
      this.testLabExist = false
      this.programExist = false
      this.patientId = ''
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.submitting = true
          console.log('Received values of form: ', values)
          try {
            const res = await getByCode(values.code)
            this.surveyResult = res.resultSurvey.length
              ? res.resultSurvey[0]
              : {}
            console.log(res)
            this.patientId = res.id
            this.testLabExist = true
            this.programExist = true
            if (res.testLabs && res.testLabs.length) {
              this.testLabExist = true
              this.testLab = res.testLabs[0]
              if (
                res.testLabs[0].testLabEvidences &&
                res.testLabs[0].testLabEvidences.length
              ) {
                this.fileList[0] = {
                  uid: '-1',
                  name: res.testLabs[0].testLabEvidences[0].url.split('/')[
                    res.testLabs[0].testLabEvidences[0].url.split('/').length -
                      1
                  ],
                  status: 'done',
                  // url: `${BASE_URL}${res.testLabs[0].testLabEvidences[0].url}`,
                  url: `${BASE_URL}${res.testLabs[0].testLabEvidences[0].url}`,
                }
              }
            }
            const redemption = await getRedemptionByCode(values.code)
            if (redemption.programs && redemption.programs.length) {
              for (const i in redemption.programs) {
                redemption.programs[i].red = redemption.programs[i].enrollDate
                  ? redemption.programs[i].enrollDate
                  : redemption.programs[i].createdAt
              }
              console.log(redemption.programs[0].red)
              const result = redemption.programs.reduce((unique, o) => {
                if (
                  !unique.some(
                    (obj) =>
                      moment(obj.red).format('YYYY/MM/DD') ===
                      moment(o.red).format('YYYY/MM/DD'),
                  )
                ) {
                  unique.push(o)
                }
                return unique
              }, [])
              this.programs = result
            }
            if (res.programs && res.programs.length) {
              this.programExist = true
              this.program = res.programs[0]
              if (
                res.programs[0].programEvidences &&
                res.programs[0].programEvidences.length
              ) {
                this.fileList2[0] = {
                  uid: '-1',
                  name: res.programs[0].programEvidences[0].url.split('/')[
                    res.programs[0].programEvidences[0].url.split('/').length -
                      1
                  ],
                  status: 'done',
                  // url: `${BASE_URL}${res.programs[0].programEvidences[0].url}`,
                  url: `${BASE_URL}${res.programs[0].programEvidences[0].url}`,
                }
              }
            }
            // if (res && res.data) {
            //   notification.success({
            //     message: 'Sukses',
            //     description: 'Data berhasil ditambahkan',
            //     duration: 5,
            //   })
            // }
            this.submitting = false
          } catch (e) {
            this.submitting = false
          }
        }
      })
    },
    handleChange(info) {
      let fileList = [...info.fileList]
      fileList = fileList.slice(-1)
      fileList = fileList.map((file) => {
        if (file.response) {
          file.url = file.response.url
        }
        return file
      })

      this.fileList = fileList
    },
    handleChange2(info) {
      let fileList2 = [...info.fileList2]
      fileList2 = fileList2.slice(-1)
      fileList2 = fileList2.map((file) => {
        if (file.response) {
          file.url = file.response.url
        }
        return file
      })

      this.fileList2 = fileList2
    },
    async handleDelete() {
      const res = await terminateProgramDashboard({
        id: this.program ? this.program.id : '',
        message: this.alasanBerhenti,
      })
      if (res && res.data) {
        notification.success({
          message: 'Sukses',
          description: 'Program berhasil diberhentikan',
          duration: 5,
        })
        router.push('/pasien')
      } else {
        notification.warning({
          message: 'Gagal',
          description: 'Program gagal diberhentikan',
          duration: 5,
        })
      }
      this.submitting = false
      this.modalVisible = false
    },
    handleCancel(e) {
      e.preventDefault()
      router.back()
    },
    async handleContinue() {
      const res = await continueProgram({
        id: this.program ? this.program.id : '',
      })
      if (res && res.data) {
        notification.success({
          message: 'Sukses',
          description: 'Program berhasil diperpanjang',
          duration: 5,
        })
        router.push('/pasien')
      } else {
        notification.warning({
          message: 'Gagal',
          description: 'Program gagal diperpanjang',
          duration: 5,
        })
      }
      this.submitting = false
      this.modalVisible = false
    },
    async handleResetPassword() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.submitting = true
          console.log('Received values of form: ', values)
          try {
            const res = await resetPassword(values, 'patient')
            if (res && res.data) {
              notification.success({
                message: 'Sukses',
                description:
                  'Password berhasil direset, silakan cek email terdaftar',
                duration: 5,
              })
            } else {
              notification.warning({
                message: 'Gagal',
                description: 'Password gagal direset',
                duration: 5,
              })
            }
            this.submitting = false
          } catch (e) {
            this.submitting = false
          }
          this.modalVisible2 = false
        }
      })
    },
    setModalVisible(modalVisible) {
      this.modalVisible = modalVisible
    },
    setModalVisible2(modalVisible2) {
      this.modalVisible2 = modalVisible2
    },
    getRole() {
      const role = window.localStorage.getItem('role')
      // console.log(role)
      if (role === 'admin') {
        this.isAdmin = true
      }
    },
  },
}
</script>
